import React, { useEffect } from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import BlogDetails from "@/components/blog-details";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import HeaderOne from "@/components/header-one";
import { BlogData } from "@/data";

const slugify = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

const removeTags = str => str.replace(/<[^>]*>/g, '');

const truncateString = (str, num) =>
  str.length > num ? `${str.slice(0, num)}...` : str;

const BlogSinglePage = (props) => {
  const slug = props.params.slug;
  const isBrowser = typeof window !== "undefined";
  let host = "";

  useEffect(() => {
    if (isBrowser) {
      host = `${window.location.protocol}//${window.location.host}`;
    }
  }, [isBrowser]);

  const blog = BlogData.find(o => slugify(o.title) === slug);
  const blogImg = `${host}${blog ? blog.image : ""}`;
  const blogDesc = truncateString(removeTags(blog ? blog.content: ""), 100);
  const title = blog ? blog.title : "";

  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle={title}
          PageDescription={blogDesc}
          PageImage={blogImg}>
          <HeaderOne />
          <PageBanner title={title} name="Blog" />
          <BlogDetails blog={blog} />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default BlogSinglePage;
